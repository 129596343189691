import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import accountClient from 'api/accountClient';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { getAzureChangePasswordUrl } from 'helpers/azureRoutingHelper';
import { accountRoutes } from 'helpers/routingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { useUser } from 'state/user/state';
import { CsMeta } from 'types/contentStack';

type ChangePasswordProps = {
  data: {
    csChangePasswordPage: {
      meta: CsMeta;
    };
  };
};

export const query = graphql`
  query {
    csChangePasswordPage {
      meta {
        meta_title
      }
    }
  }
`;

const ChangePassword: React.FC<ChangePasswordProps> = ({
  data: {
    csChangePasswordPage: { meta },
  },
}) => {
  const [user] = useUser();
  const { requestHandler } = useApiRequestHandler();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.isLoggedIn) {
      navigate(accountRoutes.login);
      return;
    }
    requestHandler(() =>
      accountClient.getNonce().then(({ nonce }) => {
        window.location.replace(getAzureChangePasswordUrl(nonce));
      })
    );
  }, [requestHandler, user]);

  return (
    <Layout meta={meta} pageType="accountArea">
      <LoadingOverlay loadingMessage="Redirecting to registration page" />
    </Layout>
  );
};

export default ChangePassword;
